import api from "@a/config";

export default {
  importDocument: (standort_id, date, file, dnumber) => {
    let formData = new FormData();
    formData.append("Standort", standort_id);
    formData.append("GueltigAb", date);  // dd.MM.yyyy
    formData.append("Dokument", file);
    formData.append("Dienstnummer", dnumber);

    return api.post(`api/diensterlaeuterungen/import/`, formData, {
      baseURL: '/',
      headers: {
        'Content-Type': 'multipart/formdata'
      }
    })
  }
};
